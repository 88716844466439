var acf_map = (function($) {
    // Rootfolder for image assets.
    var imageFolder = felm_general.site_url + '/app/themes/lahetysseura/dist/images/';
/*
*  add_map_style
*
* This function will add custom styling to Google Map
*
* @type 	function
* @data 	5/17/2017
* @since    5.13.0
*
* @param	jotain
* @return 	map styles
*/
function add_map_style (style) {

		var $felmMapStyle = new google.maps.StyledMapType(
				[
				{
				"elementType": "geometry",
				"stylers": [
				  {
					"color": "#f5f5f5"
				  }
				]
				},
				{
				"elementType": "labels",
				"stylers": [
				  {
					"visibility": "off"
				  }
				]
				},
				{
				"elementType": "labels.icon",
				"stylers": [
				  {
					"visibility": "off"
				  }
				]
				},
				{
				"elementType": "labels.text.fill",
				"stylers": [
				  {
					"color": "#616161"
				  }
				]
				},
				{
				"elementType": "labels.text.stroke",
				"stylers": [
				  {
					"color": "#f5f5f5"
				  }
				]
				},
				{
				"featureType": "administrative.country",
				"elementType": "geometry",
				"stylers": [
				  {
					"visibility": "on"
				  },
				  {
				  	"color": "#cccccc"
				  }
				]
				},
				{
				"featureType": "administrative.land_parcel",
				"stylers": [
				  {
					"visibility": "off"
				  }
				]
				},
				{
				"featureType": "administrative.land_parcel",
				"elementType": "labels.text.fill",
				"stylers": [
				  {
					"color": "#bdbdbd"
				  }
				]
				},
				{
				"featureType": "administrative.neighborhood",
				"stylers": [
				  {
					"visibility": "off"
				  }
				]
				},
				{
				"featureType": "poi",
				"elementType": "geometry",
				"stylers": [
				  {
					"color": "#eeeeee"
				  }
				]
				},
				{
				"featureType": "poi",
				"elementType": "labels.text.fill",
				"stylers": [
				  {
					"color": "#757575"
				  }
				]
				},
				{
				"featureType": "poi.park",
				"elementType": "geometry",
				"stylers": [
				  {
					"color": "#e5e5e5"
				  }
				]
				},
				{
				"featureType": "poi.park",
				"elementType": "labels.text.fill",
				"stylers": [
				  {
					"color": "#9e9e9e"
				  }
				]
				},
				{
				"featureType": "road",
				"elementType": "geometry",
				"stylers": [
				  {
					"color": "#ffffff"
				  }
				]
				},
				{
				"featureType": "road.arterial",
				"stylers": [
				  {
					"visibility": "off"
				  }
				]
				},
				{
				"featureType": "road.arterial",
				"elementType": "labels.text.fill",
				"stylers": [
				  {
					"color": "#757575"
				  }
				]
				},
				{
				"featureType": "road.highway",
				"elementType": "geometry",
				"stylers": [
				  {
					"color": "#dadada"
				  }
				]
				},
				{
				"featureType": "road.highway",
				"elementType": "labels",
				"stylers": [
				  {
					"visibility": "off"
				  }
				]
				},
				{
				"featureType": "road.highway",
				"elementType": "labels.text.fill",
				"stylers": [
				  {
					"color": "#616161"
				  }
				]
				},
				{
				"featureType": "road.local",
				"stylers": [
				  {
					"visibility": "off"
				  }
				]
				},
				{
				"featureType": "road.local",
				"elementType": "labels.text.fill",
				"stylers": [
				  {
					"color": "#9e9e9e"
				  }
				]
				},
				{
				"featureType": "transit.line",
				"elementType": "geometry",
				"stylers": [
				  {
					"color": "#e5e5e5"
				  }
				]
				},
				{
				"featureType": "transit.station",
				"elementType": "geometry",
				"stylers": [
				  {
					"color": "#eeeeee"
				  }
				]
				},
				{
				"featureType": "water",
				"elementType": "geometry",
				"stylers": [
				  {
					"color": "#c9c9c9"
				  }
				]
				},
				{
				"featureType": "water",
				"elementType": "labels.text.fill",
				"stylers": [
				  {
					"color": "#9e9e9e"
				  }
				]
				},
				{
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "hue": "#eeeeee"
            },
            {
                "saturation": -100
            },
            {
                "lightness": -3
            },
            {
                "visibility": "on"
            }
        ]
    }
			],
			{name: 'Styled Map'});
    var $felmRoadMapStyle = new google.maps.StyledMapType(
        [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "featureType": "administrative.country",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#cccccc"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "administrative.neighborhood",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dadada"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#c9c9c9"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#eeeeee"
                    },
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": -3
                    },
                    {
                        "visibility": "on"
                    }
                ]
            }
        ],
        {name: 'Styled Map'});
		if ( style === 'default' ){
            return $felmMapStyle;
		} else {
            return $felmRoadMapStyle;
		}

}
/*
*  make_label
*
*  This function makes a label for Google Map Marker
*
*/
function make_label(label_color, label_font_family, label_font_size, label_font_weight, label_text){
	var label = {
		color 		: label_color,
		fontFamily	: label_font_family,
		fontSize	: label_font_size,
		fontWeight 	: label_font_weight,
		text 		: label_text
	};

	return label;
}

/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type	function
*  @date	8/11/2013 modified 5/17/2017
*  @since	4.3.0
*
*  @param	$marker (jQuery element)
*  @param	map (Google Map object)
*  @return	n/a
*/
function add_marker( $marker, map) {
    var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
    var green_icon = {
    	anchor: new google.maps.Point(18,23),
        labelOrigin: new google.maps.Point(18, 23),
        url: imageFolder + 'pk_map_icon.svg',
        scaledSize: new google.maps.Size(36, 47),
	};
    var green_icon_hover = {
        anchor: new google.maps.Point(18,23),
        labelOrigin: new google.maps.Point(18, 23),
        url: imageFolder + 'pk_map_icon_hover.svg',
        scaledSize: new google.maps.Size(36, 47),
    };
    var selectedIcon = green_icon;
    var selectedIconHover = green_icon_hover;

    // create marker
    var marker = new google.maps.Marker({
        position	: latlng,
        map			: map,
        icon 		: selectedIcon,
        //url 		: link,
        //label       : make_label('white','Montserrat', '12px', '600',labeltxt),
        //zIndex		: parseInt(zIndex),
        cursor		: 'pointer',
        optimized   : false,
    });

    // add to array
    map.markers.push( marker );

    // if marker contains HTML, add it to an infoWindow
    if( $marker.html() )
    {
        // create info window
        var infowindow = new google.maps.InfoWindow({
            content		: $marker.html()
        });

        // show info window when marker is clicked
        google.maps.event.addListener(marker, 'click', function() {

            infowindow.open( map, marker );
            //window.location.href = this.url;

        });

        // Hover in
        google.maps.event.addListener(marker, 'mouseover', function() {

            // Change the icon on hover.
            currentZIndex = marker.getZIndex();
            marker.setIcon(selectedIconHover);
            //marker.setZIndex(google.maps.Marker.MAX_ZINDEX + 1);

        });
        // Hover out
        google.maps.event.addListener(marker, 'mouseout', function() {

            // Change the icon on hover.
            marker.setIcon(selectedIcon);
            //marker.setZIndex(currentZIndex);


        });

    }

}

function add_direct_link_marker( $marker, map ) {

	// var
	var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
	var link = $marker.attr('data-url');
	var labeltxt = $marker.attr('data-country');
	var zIndex = $marker.attr('data-zindex');
	var currentZIndex = null;

	/**
	*	Map Marker Icons
	*
	*	anchor: We must move the anchor relative to the size of the icon.
	*	labelOrigin: The label must also be repositioned relative to the sixe of the icon.
	*	url: Here we are using inline SVG to render the icon. This could be change based on charlength.
	*/
    var biggericon = {
        anchor: new google.maps.Point(65, 14),
        labelOrigin: new google.maps.Point(65, 10),
        url: imageFolder + 'label-bigger.svg',
        scaledSize: new google.maps.Size(130, 28),
    };
    var biggericon_hover = {
        anchor: new google.maps.Point(65, 14),
        labelOrigin: new google.maps.Point(65, 10),
        url: imageFolder + 'label-bigger-hover.svg',
        scaledSize: new google.maps.Size(130, 28),
    };
	var bigicon = {
		anchor: new google.maps.Point(55, 14),
		labelOrigin: new google.maps.Point(55, 10),
		url: imageFolder + 'label-big.svg',
        scaledSize: new google.maps.Size(110, 28),
	};
	var bigicon_hover = {
		anchor: new google.maps.Point(55, 14),
		labelOrigin: new google.maps.Point(55, 10),
		url: imageFolder + 'label-big-hover.svg',
        scaledSize: new google.maps.Size(110, 28),
	};
    var mediumicon = {
        anchor: new google.maps.Point(37.5, 14),
        labelOrigin: new google.maps.Point(37.5, 10),
        url: imageFolder + 'label-medium.svg',
        scaledSize: new google.maps.Size(75, 28),
    };
    var mediumicon_hover = {
        anchor: new google.maps.Point(37.5, 14),
        labelOrigin: new google.maps.Point(37.5, 10),
        url: imageFolder + 'label-medium-hover.svg',
        scaledSize: new google.maps.Size(75, 28),
    };
    var smallicon = {
        anchor: new google.maps.Point(26.5, 14),
        labelOrigin: new google.maps.Point(26.5, 10),
        url: imageFolder + 'label-small.svg',
        scaledSize: new google.maps.Size(53, 28),
    };
    var smallicon_hover = {
        anchor: new google.maps.Point(26.5, 14),
        labelOrigin: new google.maps.Point(26.5, 10),
        url: imageFolder + 'label-small-hover.svg',
        scaledSize: new google.maps.Size(53, 28),
    };
    var selectedIcon = biggericon;
    var selectedIconHover = biggericon_hover;

    if(labeltxt.length < 7){
        selectedIcon = smallicon;
        selectedIconHover = smallicon_hover;
    } else if(labeltxt.length < 10){
        selectedIcon = mediumicon;
        selectedIconHover = mediumicon_hover;
	} else if(labeltxt.length < 17){
        selectedIcon = bigicon;
        selectedIconHover = bigicon_hover;
	}

	// create marker
	var marker = new google.maps.Marker({
		position	: latlng,
		map			: map,
		icon 		: selectedIcon,
		url 		: link,
		label       : make_label('white','Montserrat', '12px', '600',labeltxt),
		zIndex		: parseInt(zIndex),
		cursor		: 'pointer',
        optimized   : false,
	});

	// add to array
	map.markers.push( marker );

	// if marker contains HTML, add it to an infoWindow
	if( $marker.html() )
	{
		// create info window
		var infowindow = new google.maps.InfoWindow({
			content		: $marker.html()
		});

		// show info window when marker is clicked
		google.maps.event.addListener(marker, 'click', function() {

			//infowindow.open( map, marker );
			 window.location.href = this.url;

		});

		// Hover in
		google.maps.event.addListener(marker, 'mouseover', function() {

			// Change the icon on hover.
			currentZIndex = marker.getZIndex();
			marker.setIcon(selectedIconHover);
			marker.setZIndex(google.maps.Marker.MAX_ZINDEX + 1);

		});
		// Hover out
		google.maps.event.addListener(marker, 'mouseout', function() {

			// Change the icon on hover.
			marker.setIcon(selectedIcon);
			marker.setZIndex(currentZIndex);


		});

	}

}

/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	map (Google Map object)
*  @return	n/a
*/

function center_map( map ) {

	// vars
	var bounds = new google.maps.LatLngBounds();

	// loop through all markers and create bounds
	$.each( map.markers, function( i, marker ){

		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

		bounds.extend( latlng );

	});

	// only 1 marker?
	if( map.markers.length === 1 )
	{
		// set center of map
	    map.setCenter( bounds.getCenter() );
	    map.setZoom( 16 );
	}
	else
	{
		// fit to bounds
		map.fitBounds( bounds );
	}

}

/*
*  new_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type	function
*  @date	8/11/2013 edited 5/17/2017
*  @since	4.3.0
*
*  @param	$el (jQuery element)
*  @return	n/a
*/

function new_map( $el ) {

	// var
	var $markers = $el.find('.marker');


	// vars
	var args = {
		zoom		: 16,
		center		: new google.maps.LatLng(0, 0),
		mapTypeId	: google.maps.MapTypeId.ROADMAP,
		scrollwheel	: false,
        mapTypeControl: false
	};


	// create map
	var map = new google.maps.Map( $el[0], args);

	// add custom styling to the map
	map.mapTypes.set('styled_map', add_map_style('default'));
	map.setMapTypeId('styled_map');


	// add a markers reference
	map.markers = [];


	// add markers
	$markers.each(function(){

        add_direct_link_marker( $(this), map );

	});


	// center map
	center_map( map );


	// return
	return map;

}
function new_header_map( $el ) {
	// Find markes
    var $markers = $el.find('.marker');

    // Map creation settings
    var args = {
        zoom		: 16,
        center		: new google.maps.LatLng(0, 0),
        mapTypeId	: google.maps.MapTypeId.ROADMAP,
        scrollwheel	: false,
        mapTypeControl: false
    };

    // create map
    var map = new google.maps.Map( $el[0], args);

    // add custom styling to the map
    map.mapTypes.set('styled_map', add_map_style('road'));
    map.setMapTypeId('styled_map');

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function(){

        add_marker( $(this), map );

    });


    // center map
    center_map( map );


    // return
    return map;
}

return {
	new_map: new_map,
    new_header_map: new_header_map
};

})(jQuery);
