var FELM = FELM || {};
/*global
 jQuery, FELM
 */
/**
 * Accessibility Helpers
 */
(function ($) {
    'use strict';
    FELM.keyboard = {
        back: 8, // delete key on mac
        tab: 9,
        enter: 13,
        shift: 16, // shiftKey = true
        ctrl: 17, // ctrlKey = true
        alt: 18, // (a.k.a. option on Mac) altKey = true
        esc: 27,
        space: 32,
        pageUp: 33, // fn + up on mac
        pageDown: 34, // fn + down on mac
        end: 35, // fn + right on mac
        home: 36, // fn + left on mac
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        del: 46, // fn + delete on mac
        command: 91 // metaKey = true (mac and sun machines)
    };
    /*
     a string of selectors identifying focusable elements.
     */
    FELM.focusables = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';
    /*
     namespacing general helpers
     */
    FELM.general = {};
    FELM.general.senseClickOutside = function ($evtTarget, $container) {
        if (($evtTarget).closest($container).length === 0) {
            // click target is outside
            return true;
        }
    };
// _TODO THIS NEEDS TO BE CLEANED UP.  WHAT IS arguments FOR?
    FELM.general.debounce = function (func, delay, immediate) {
        var timeout, result;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) {
                    result = func.apply(context, args);
                }
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, delay);
            if (callNow) {
                result = func.apply(context, args);
            }
            return result;
        };
    };
    /*
     namespacing accessibility helpers
     */
    FELM.access = {};
    /*
     hides content within blocked area from screen-readers
     when an element is given focus within a blocked area the focus will move to a target element
     */
    FELM.access.blockFocus = function ($blockedContainers, $focusOnThisInstead) {
        $blockedContainers.attr('aria-hidden', 'true');
        $blockedContainers.on('focusin.blockingFocus', function () {
            if ($blockedContainers.attr('aria-hidden') === 'true') {
                $focusOnThisInstead.focus();
                return false;
            }
        });
    };
    /*
     removes focus blocking that was set with FELM.access.blockFocus method
     */
    FELM.access.removeBlockFocus = function ($blockedContainers) {
        $blockedContainers.off('focusin.blockingFocus');
        $blockedContainers.removeAttr('aria-hidden');
    };
    /*
     aria tagging while expanding content
     */
    FELM.access.ariaExpand = function ($expander, $expandingContainer) {
        $expander.attr('aria-expanded', 'true');
        $expandingContainer.attr('aria-hidden', 'false');
    };

    /*
     aria tagging while contracting content
     */
    FELM.access.ariaContract = function ($expander, $expandingContainer) {
        $expander.attr('aria-expanded', 'false');
        $expandingContainer.attr('aria-hidden', 'true');
    };

    /*
     hide visible content with aria
     use when you have a content that must still be visible i.e. carousel panels
     */
    FELM.access.ariaHideContent = function ($hideContainers) {
        // find all focusable elements
        // IMPORTANT: assumes no tabindex > -1 elems
        var $focusableHiddenElems = $hideContainers.find('a, button, input, select, textaria');
        // hide the container with aria
        $hideContainers.attr('aria-hidden', 'true');
        // remove focusability from focusable elements
        $focusableHiddenElems.attr('tabindex', '-1')
        // and add data attribute that flags the focusable elements for reset with ariaShowContent()
            .attr('data-focusable-hidden-elem', 'true');
    };

    /*
     reset the content hidden with ariaHideContent() to its original accessibility state
     */
    FELM.access.ariaShowContent = function ($blockedContainers) {
        var $focusableHiddenElems = $blockedContainers.find('[data-focusable-hidden-elem]');
        $blockedContainers.removeAttr('aria-hidden');
        $focusableHiddenElems.removeAttr('tabindex')
            .removeAttr('data-focusable-hidden-elem');
    };

    /*
     tag trigger ($OPTIONALtrigger can be used to override default trigger capture)
     NOTE: It's preferrable to pass $OPTIONALtrigger because Safari does not focus during click event.
     */
    FELM.access.tagTrigger = function ($OPTIONALtrigger) {
        var $trigger;
        if ($OPTIONALtrigger) {
            $trigger = $OPTIONALtrigger;
        } else {
            $trigger = $(document.activeElement);
        }
        $trigger.attr('data-trigger', 'true');
    };

    /*
     focuses on trigger and removes trigger tag ($OPTIONALtrigger can be used to override default trigger capture)
     */
    FELM.access.focusTrigger = function ($OPTIONALtrigger) {
        var $trigger;
        if ($OPTIONALtrigger) {
            $trigger = $OPTIONALtrigger;
        } else {
            $trigger = $('[data-trigger="true"]');
        }
        $trigger.focus().removeAttr('data-trigger');
    };

    /*
     injects hidden announcement to be read by screen-readers
     $ariaContainer is an existing, empty element with either aria-live="polite" or role="alert"
     */
    FELM.access.announcements = function ($ariaContainer, textToRead) {
        $ariaContainer.text(textToRead);
        setTimeout(function () {
            $ariaContainer.text('');
        }, 1000);
    };

    /*
     reads on-screen text without changing focus
     $hasTextToBeRead is either a single or array of jQuery selector(s) with the text to be read
     */
    FELM.access.duplicateTextForReading = function ($ariaContainer, $hasTextToBeRead) {
        var textToBeRead = '';
        $.each($hasTextToBeRead, function () {
            textToBeRead += $(this).text();
            textToBeRead += ' ';
        });
        FELM.access.announcements($ariaContainer, textToBeRead);
    };
}(jQuery));
/**
 * Globals
 * jQuery, FELM, window, felm_general (from wp_localize_script)
 */
/**
 * Accessibility Helpers
 */
(function ($, FELM) {
    'use strict';
    var $widget = $('[data-widget="accessible-autocomplete"]'),
        $input = $widget.find('#felmContactSearch'),
        $form = $('#felmContactSearchContainer form'),
        $clearText = $('#clearText'),
        inputVal = "",
        myExp = new RegExp($input.val(), "i"),
        $results = $widget.find('#results'),
        results = [],
        $live = $widget.find('[aria-live]'),
        key = FELM.keyboard,
        directions = felm_general.c_search_directions,
        liMarkup = '<li id="" class="autocomplete-item" role="option" aria-selected="false" tabindex="-1">',
        resultJson = '',
        resultSet = [];
        if( $widget.length > 0 ) {
            // We get the resultset only once.
            $.getJSON( felm_general.site_url + '/wp-json/wp/v2/felm_personnel', {per_page: 220}, function( data ){
                resultJson = data;
            });
        }

    // THE ARRAY WOULD REALLY COME IN VIA AJAX
    function searchResults() {
        if ($input.val().length === 0 || $input.val().length < 3) {
            return [];
        }
        if($input.val().length >= 3){
            // Empty resultSet from possible previous searches.
            resultSet = [];
            // Make expression to match results from string.
            myExp = new RegExp($input.val(), "i");

            $.each(resultJson, function( key, val ) {
                // See if the input value matches any defined strings from data.
                if ( (val.firstname.search(myExp) !== -1) ||
                    (val.lastname.search(myExp) !== -1) ||
                    (val.jobtitle.search(myExp) !== -1) ||
                    (val.post_title.search(myExp) !== -1) ) {

                    // Push the qualified set to results.
                    resultSet.push(val);
                }

            });
            return resultSet;
        }

    }
    function positionResults() {
        // Check that we are not dealing with undefined.
        if( (typeof $results !== 'undefined') && (typeof $input !== 'undefined') ){
            // stop if this has already been set
            if ($results.is('[style*="width"]')) {
                return;
            }
            if( typeof $input.position() !== 'undefined'){
                $results.css({
                    left: $input.position().left + "px",
                    top: $input.position().top + $input.outerHeight() + "px",
                    "min-width": $input.outerWidth() + "px"
                });
            }
        }

    }
    function buildListHtml(results) {
        var resultsMarkup = "", i = 0;
        for (i = 0; i < results.length; i += 1) {
            resultsMarkup += liMarkup + results[i].post_title + "</li>";
        }
        $results.html(resultsMarkup);
        $results.show();
        $input.attr('aria-expanded', 'true');
    }
    function announceResults() {
        var number = results.length,
            textToRead = number + " results are available. " + directions;
        // if results length === 0 then say "no search results"
        if (results.length === 0) {
            textToRead = "No search results";
        }
        FELM.access.announcements($live, textToRead);
    }
    function markSelected($selectionToMark) {
        // don't mark anything on the results list if we're back at the input field
        if ($selectionToMark.length === 0) {
            return;
        }
        var activeItemId = 'selectedOption';
        $selectionToMark.attr('aria-selected', 'true').attr('id', activeItemId);
        $input.attr('aria-activedescendant', activeItemId);
    }
    function clearSelected() {
        $input.attr('aria-activedescendant', '');
        $results.find('[aria-selected="true"]').attr('aria-selected', 'false').attr('id', '');
    }
    function closeResults() {
        clearSelected();
        $results.hide();
        $input.attr('aria-expanded', 'false');
    }
    function autocomplete() {
        // if input value didn't change, return
        if ($input.val() === inputVal) {
            return;
        }
        // save new input value
        inputVal = $input.val();
        // get and save autocomplete results
        results = searchResults();
        // build list HTML
        if (results.length === 0) {
            closeResults();
        } else {
            buildListHtml(results);
        }
        // aria-live results
        announceResults();
    }
    function arrowing(kc) {
        var $thisActiveItem = $results.find('[aria-selected="true"]'),
            $nextMenuItem;
        // don't do anything if no results
        if (!results.length) {
            return;
        }
        if (kc === key.down) {
            // find the next list item to be arrowed to
            $nextMenuItem = ($thisActiveItem.length !== 0) ? $thisActiveItem.next('li') : $results.children().eq(0); //first item in list
        }
        if (kc === key.up) {
            // find the previous list to be arrowed to
            $nextMenuItem = ($thisActiveItem.length !== 0) ? $thisActiveItem.prev('li') : $results.children().eq(-1); //last item in list
        }
        clearSelected();
        markSelected($nextMenuItem);
    }
    function populating() {
        var selectedText = $results.find('[aria-selected="true"]').text();
        if (selectedText === "") {
            selectedText = inputVal;
        }
        $input.val(selectedText);
    }
    function handleSubmit() {
      results = searchResults();
        console.log(results);

      if( (typeof results === 'undefined') || (results.length === 0 ) || (results.length > 1) ){
          console.log('handleSubmit.results');
          console.log(results);
          return;

      } else {
          // Fetch image data
          var postThumbId = results[0].felm_post_thumb_id;
          var restThumbURL = felm_general.site_url + '/wp-json/wp/v2/media/' + postThumbId;
          var thumb;
          var thumbW = '';
          var thumbH = '';
          var thumbSrc = '';
          var thumbAlt = '';

          if( postThumbId !== 0 ){
              $.getJSON( restThumbURL, function( data ){

              })
                  .done(function( data ) {
                      console.log(data);
                      thumbAlt = data.alt_text;
                      thumb = data.media_details.sizes["felm-thumbnail"];
                      if ( typeof thumb === "undefined" ) {
                          thumb = data.media_details.sizes.thumbnail;
                          if ( typeof thumb === "undefined" ) {
                              thumb = data.media_details;
                          }
                      }
                      thumbW = thumb.width;
                      thumbH = thumb.height;
                      thumbSrc = thumb.source_url;
                      if (typeof thumbSrc === "undefined") {
                          thumbSrc = data.source_url;
                      }
                      // Generate data and initiaton for Bootstrap modal dialog.
                      $('#felmPersonModalSearch').modal();
                      console.log('Felm personel modal creation succeeded.');

                  }).fail(function(jqxhr, textStatus, error){
                      console.log('Felm personnel modal mediarequest failed: ' + textStatus + ' error: ' + error + ' jqxhr: ' + jqxhr );
                  $('#felmPersonModalSearch').modal();
              });
          }
          // Felm Person Modal card on show.
          $('#felmPersonModalSearch').on('show.bs.modal', function (event) {

              var data = results[0];
              var name = data.post_title; // Extract info from data-* attributes
              var mobile = data.mobile;
              var tel = data.tel;
              var job = data.jobtitle;
              var email = data.email;
              var info = data.resparea;
              var countries = data.countries;

              // Image needs to come through REST API.
              var imgsrc = thumbSrc;
              var imgalt = thumbAlt;


              // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
              // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
              var modal = $(this);

              modal.find('.modal-title').text(name);
              modal.find('.felm-person-name').text(name);

              if(countries.length > 0){
                  modal.find('.felm-job-title').text(job + ', ' + countries);
              } else {
                  modal.find('.felm-job-title').text(job);
              }

              if( (typeof imgsrc !== 'undefined') && imgsrc.length > 5){

                  modal.find('.file-card-thumb img').attr('src', imgsrc);
                  modal.find('.file-card-thumb img').attr('alt', imgalt);
              } else {
                  imgsrc = felm_general.felm_c_card_img;

                  modal.find('.file-card-thumb img').attr('src', imgsrc);
                  modal.find('.file-card-thumb img').attr('alt', imgalt);
              }

              if( typeof tel !== 'undefined' && tel.length > 5 ){

                  modal.find('.felm-person-tel-link').html('<span class="felm-person-detail felm-person-tel">' + tel + '</span>');
                  modal.find('.felm-person-tel-link').attr('href', 'tel:' + tel);

              }
              if( typeof mobile !== 'undefined' && mobile.length > 5 ){

                  modal.find('.felm-person-mobile-link').html('<span class="felm-person-detail felm-person-mobile">' + mobile + '</span>');
                  modal.find('.felm-person-mobile-link').attr('href', 'tel:' + mobile);

              }
              if(  typeof email !== 'undefined' && email.length > 5 ){
                  modal.find('.felm-person-email-link').html('<span class="felm-person-detail felm-person-email">' + email + '</span>');
                  modal.find('.felm-person-email-link').attr('href', 'mailto:' + email);

              }
              if(  typeof email !== 'undefined' && email.length > 5 ){
                  modal.find('.felm-person-responsibilities').html(info);
              }

          });
          if( postThumbId === 0 ){
              $('#felmPersonModalSearch').modal();
          }

      }

    }
    function eventListeners() {
        // close results if click outside $input and $results
        $(document).on('click', function (e) {
            var $container = $input.add($results);
            if (FELM.general.senseClickOutside($(e.target), $container)) {
                closeResults();
                return;
            }
        });

        $form.submit(function (e) {
            e.preventDefault();
            handleSubmit();
        });

        $input.on('keyup', function (e) {
            var kc = e.keyCode;
            if (kc === key.up || kc === key.down || kc === key.tab || kc === key.enter || kc === key.esc) {
                return;
            }
            autocomplete();
        });
        $input.on('keydown', function (e) {
            var kc = e.keyCode;
            if (kc === key.tab) {
                closeResults();
                return;
            }
            if (kc === key.enter) {
                e.preventDefault();
                closeResults();
                handleSubmit();
                return;
            }
            if (kc === key.up || kc === key.down) {
                e.preventDefault();
                arrowing(kc);
                populating();
                return;
            }
            if (kc === key.esc) {
                $input.val(inputVal);
                closeResults();
            }
        });
        $results.on('click', function (e) {
            $input.val(e.target.textContent);
            closeResults();
            $input.focus();
        });
        $results.hover(function () {
            clearSelected();
        });
        $clearText.on('click', function () {
            inputVal = '';
            $input.val(inputVal);
        });
    }
    function init() {
        eventListeners();
        positionResults();
    }
    init();
}(jQuery, FELM));
