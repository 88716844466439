//change scroll effects

	jQuery(document).on('click', '.scroll-effects', function () {
	    _anim = jQuery(this).data('scroll-effect');
	    jQuery('.flik-timeline').attr('data-scroll-effect', _anim);
	    jQuery("html, body").animate({ scrollTop: 0 }, 200);

	    setTimeout(function () {
	        //hide timeline blocks which are outside the viewport
	        jQuery('.flik-timeline').children('li').each(function () {
	            _element = jQuery(this);
	            if (_element.offset().top > jQuery(window).scrollTop() + jQuery(window).height() * 0.75) {
	                _element.addClass('is-hidden');
	                jQuery('.controls .scroll-effects').each(function () {
	                    _element.removeClass(jQuery(this).data('scroll-effect'));
	                });
	            }
	        });


	    }, 250);

	    jQuery('.controls .scroll-effects').each(function () {
	        jQuery(this).removeClass('active');
	    });

	    jQuery(this).addClass('active');
	    return false;
	});


	/* flikSlider */

	function flikScrollEffect(_selector) {



	    //hide timeline blocks which are outside the viewport
	    _selector.children('li').each(function () {
	        _element = jQuery(this);
	        if (_element.offset().top > jQuery(window).scrollTop() + jQuery(window).height() * 0.75) {
	            _element.addClass('is-hidden');
	            jQuery('.controls .scroll-effects').each(function () {
	                _element.removeClass(jQuery(this).data('scroll-effect'));
	            });
	        }
	    });
	    //  _anim = typeof _anim !== 'undefined' ? _anim : 'default-effect';
	    _anim = _selector.data('scroll-effect');

	    //on scolling, show/animate timeline blocks when enter the viewport
	    jQuery(window).on('scroll', function () {

	        _selector.children('li').each(function () {
	            // console.log(jQuery(this));

	            if (jQuery(this).offset().top <= (jQuery(window).scrollTop() + jQuery(window).height() * 0.75) && jQuery(this).hasClass('is-hidden')) {
	                jQuery(this).removeClass('is-hidden').addClass(_anim);
	            }
	        });
	    });
	}


	(function ($) {
	    /* flikScrollEffect */
	    jQuery.fn.flikScrollEffect = function () {

	        //default settings:
	        var defaults = {

	        };

	        var settings = $.extend({}, defaults);



	        return this.each(function (i, el) {
	            console.log(settings.effect);
	            flikScrollEffect(jQuery(el));

	        });

	    };
	}(jQuery));
