/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(".felm-slick-slider").slick({

            // normal options...
            infinite: false,

            // the magic
            responsive: [{

                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true
                }

              }, {

                breakpoint: 780,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  dots: true
                }

              }, {

                breakpoint: 567,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  dots: true
                }

              }, {

                breakpoint: 400,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true
                }

              }, {

                breakpoint: 300,
                settings: "unslick" // destroys slick

              }]
          });


          $('.felm-article-slider').slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              fade: true,
              adaptiveHeight: true,
              asNavFor: '.felm-article-slider-nav'
          });

          // Set position for slider arrows.
          var slideImgWrapper = $('.felm-article-slider .felm-article-slider-16by9');
          var slideImgWrapperHeight = slideImgWrapper.height();
          var newHeight = 0;

          if(slideImgWrapperHeight > 0) {

              var slideNext = $('.felm-article-slider .slick-prev').css('top', slideImgWrapperHeight/2);
              var slidePrev =$('.felm-article-slider .slick-next').css('top', slideImgWrapperHeight/2);

              // Set the size again on resize.
              $(window).resize(function() {
                  newHeight = slideImgWrapper.height();
                  if(newHeight > 0) {
                      slideNext.css('top', newHeight / 2);
                      slidePrev.css('top', newHeight / 2);
                  }
              });

          }


          $('.felm-article-slider-nav').slick({

              accessibility: true,
              dots: false,
              centerMode: false,
              focusOnSelect: true,
              infinite: false,
              asNavFor: '.felm-article-slider',
              slidesToScroll: 1,
              slidesToShow: 10,
              adaptiveHeight: true,

              // the magic
              responsive: [{

                  breakpoint: 1024,
                  settings: {

                      slidesToScroll: 1,
                      slidesToShow: 8,
                      asNavFor: '.felm-article-slider',

                  }

              }, {

                  breakpoint: 800,
                  settings: {

                      slidesToScroll: 1,
                      slidesToShow: 6,
                      asNavFor: '.felm-article-slider',

                      }

                  },{

                  breakpoint: 600,
                  settings: {

                      slidesToScroll: 1,
                      slidesToShow: 5,
                      asNavFor: '.felm-article-slider',

                  }

              }, {

                  breakpoint: 400,
                  settings: {

                      slidesToScroll: 1,
                      slidesToShow: 4,
                      asNavFor: '.felm-article-slider',

                  }

              }, {

                  breakpoint: 300,
                  settings: {

                    slidesToScroll: 1,
                    slidesToShow: 3,
                    asNavFor: '.felm-article-slider',
                  }
              }]
          });

        function scrollFunction() {
            if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
                document.getElementById("scrlTopBtn").style.display = "block";
            } else {
                document.getElementById("scrlTopBtn").style.display = "none";
            }
        }

        // When the user clicks on the button, scroll to the top of the document
        function topFunction() {
            document.body.scrollTop = 0; // For Chrome, Safari and Opera
            document.documentElement.scrollTop = 0; // For IE and Firefox
        }
        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            scrollFunction();
        };

        // Scroll to top on click.
        $('#scrlTopBtn').click( function(){
          topFunction();
        });

        // Felm Person Modal card on show.
        $('#felmPersonModal').on('show.bs.modal', function (event) {
          var button = $(event.relatedTarget); // Button that triggered the modal
          var name = button.data('felmname'); // Extract info from data-* attributes
          var mobile = button.data('felmmobile');
          var tel = button.data('felmtel');
          var job = button.data('felmjob');
          var email = button.data('felmemail');
          var imgsrc = button.data('felmimg');
          var imgalt = button.data('felmimgalt');
          var info = button.data('responsibilities');


            // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
          // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
          var modal = $(this);

          modal.find('.modal-title').text(name);
          modal.find('.felm-person-name').text(name);
          modal.find('.felm-job-title').text(job);
          modal.find('.file-card-thumb img').attr('src', imgsrc);
          modal.find('.file-card-thumb img').attr('alt', imgalt);

          if( typeof tel !== 'undefined' && tel.length > 5 ){

            modal.find('.felm-person-tel-link').html('<span class="felm-person-detail felm-person-tel">' + tel + '</span>');
            modal.find('.felm-person-tel-link').attr('href', 'tel:' + tel);

          }
          if( typeof mobile !== 'undefined' && mobile.length > 5 ){

            modal.find('.felm-person-mobile-link').html('<span class="felm-person-detail felm-person-mobile">' + mobile + '</span>');
            modal.find('.felm-person-mobile-link').attr('href', 'tel:' + mobile);

          }
          if(  typeof email !== 'undefined' && email.length > 5 ){
            modal.find('.felm-person-email-link').html('<span class="felm-person-detail felm-person-email">' + email + '</span>');
            modal.find('.felm-person-email-link').attr('href', 'mailto:' + email);

          }
          if( typeof info !== 'undefined' && info.length > 3 ){
              modal.find('.felm-person-responsibilities').html(info);
          }

        });

            var paivakumpuMap = null;
            $('.acf-map.acf-map-header').each(function(){
                // create map
                paivakumpuMap = acf_map.new_header_map( $(this) );
            });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // To get this to work also on multilang sites and to avoid page breaking in case title changes, we refer to
	// template name since if a page should have custom js it probably has its own template. Note the change from template-name to template_name.
    'page_template_template_tyomme': {

      init: function() {
         var map = null;
        // JavaScript to be fired on a spesific page template
        $('.acf-map').each(function(){
          // create map
          map = acf_map.new_map( $(this) );
        });
      }
    },
      'page_template_template_paivakumpu_yleinen': {

          init: function() {
              $('.felm-vr-view').each(function(){
                  var vrdiv = $(this);
                  var vrView = new VRView.Player('.felm-vr-view', {
                      width:'100%',
                      height: 480,
                      image: vrdiv.data('vrimg'),
                      is_stereo: false
                  });

              });

          }
      },
    'page_template_template_kehitysyhteistyo': {

      init: function() {
         var map = null;
        // JavaScript to be fired on a spesific page template
        $('.acf-map').each(function(){
          // create map
          map = acf_map.new_map( $(this) );
        });
      }
    },
    'page_template_template_kirkollinen_tyo': {

            init: function() {
               var map = null;
              // JavaScript to be fired on a spesific page template
              $('.acf-map').each(function(){
                // create map
                map = acf_map.new_map( $(this) );
              });
            }
          },
          'page_template_template_rauhantyo': {

            init: function() {
               var map = null;
              // JavaScript to be fired on a spesific page template
              $('.acf-map').each(function(){
                // create map
                map = acf_map.new_map( $(this) );
              });
            }
          },
    'page_template_template_lahjoitus_kummityo': {

      init: function() {
        var map = null;
        // JavaScript to be fired on a spesific page template
        $('.acf-map').each(function(){
          // create map
          map = acf_map.new_map( $(this) );
        });
      }
    },
    'page_template_template_historia': {
      init: function() {
        $('.flik-timeline').flikScrollEffect();
      }
    },
    'page_template_template_maasivu': {
      init: function() {
         var map = null;
        // JavaScript to be fired on a spesific page template
        // Trigger this when collapse is triggered.
        $('#felmMapOverlay').on('shown.bs.collapse', function () {
          $('.acf-map').each(function(){
          // create map
          if (map === null){
            map = acf_map.new_map( $(this) );
          }
        });
          google.maps.event.trigger(map, 'resize');
        });


        // Trying to make the carousel advance only one
         $('.fdi-Carousel .item').each(function () {
        var next = $(this).next();
        if (!next.length) {
            next = $(this).siblings(':first');
        }
        next.children(':first-child').clone().appendTo($(this));

        if (next.next().length > 0) {
            next.next().children(':first-child').clone().appendTo($(this));
        }
        else {
            $(this).siblings(':first').children(':first-child').clone().appendTo($(this));
        }
    });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');

    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);




	var slideCount = $('#slider ul li').length;
	var slideWidth = $('#slider ul li').width();
	var slideHeight = $('#slider ul li').height();
  var sliderUlWidth = slideCount * slideWidth;

	$('#slider').css({ width: slideWidth, height: slideHeight });

	$('#slider ul').css({ width: sliderUlWidth, marginLeft: - slideWidth });

    $('#slider ul li:last-child').prependTo('#slider ul');

  function moveLeft() {
      $('#slider ul').stop(true,true).animate({
        left: + slideWidth
      }, 1500, function () {
        $('#slider ul li:last-child').prependTo('#slider ul');
        $('#slider ul').css('left', '');
      });
  }

  function moveRight() {
      $('#slider ul').stop(true,true).animate({
        left: - slideWidth
      }, 1500, function () {
        $('#slider ul li:first-child').appendTo('#slider ul');
        $('#slider ul').css('left', '');
      });
  }

  $('a.control_prev').on('click', function(e) {
    e.preventDefault();
        moveLeft();
  });

  $('a.control_next').on('click', function(e) {
    e.preventDefault();
        moveRight();
  });


  // FRONT PAGE SLIDER
  // -----------------
  setInterval(function () {
    moveRight();
  }, 6000);







})(jQuery); // Fully reference jQuery after this point.
